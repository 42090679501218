import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'phosphor-react';

import { Button } from './styles';

function MenuButton({ onClick }) {
  return (
    <Button type="button" onClick={onClick}>
      <List size={30} />
    </Button>
  );
}

MenuButton.propTypes = {
  onClick: PropTypes.func,
};

export default MenuButton;
