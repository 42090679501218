import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.tertiary};
  padding: 3rem 0;
  position: relative;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
`;

export const AddressWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    flex-direction: row;
    text-align: left;
  }
`;
