import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@/components/atoms/Typography/styles';
import { Container, Wrapper } from './styles';
import ReturnButton from '@/components/atoms/Button/ReturnButton';

function ArticleHeader({ title, description }) {
  return (
    <Container>
      <Wrapper>
        <ReturnButton label="aboutUs.home" />
        <Text size="5xl" weight="700" color="light">
          {title}
        </Text>
        {description && (
          <Text size="xl" color="light">
            {description}
          </Text>
        )}
      </Wrapper>
    </Container>
  );
}

ArticleHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ArticleHeader;
