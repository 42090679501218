import React, { useEffect } from 'react';
import { X } from 'phosphor-react';
import PropTypes from 'prop-types';

import { HashLink } from 'react-router-hash-link';
import ExternalLinkButton from '@/components/atoms/Button/ExternalLinkButton';
import { FlexDiv } from '@/components/atoms/Containers/styles';

import { CloseButton, Content, EventImg, ModalBody, Overlay } from './styles';

function Modal({ isOpen, handleModal, bannerImage }) {
  const closeModal = () => handleModal(false);
  useEffect(() => {
    const styles = `
      body {
        overflow: ${isOpen ? 'hidden' : 'auto'};
    }
`;
    const styleSheet = document.createElement('style');
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }, [isOpen]);

  return (
    <Overlay
      isOpen={isOpen}
      onClick={closeModal}
      className={isOpen ? 'fade-in' : 'fade-in-reverse'}
    >
      <Content>
        <ModalBody onClick={(e) => e.stopPropagation()}>
          <EventImg src={bannerImage} alt="Event Banner" />
          <FlexDiv justify="center">
            <HashLink smooth to="#contact" onClick={closeModal}>
              <ExternalLinkButton url="Book a meeting" />
            </HashLink>
          </FlexDiv>
          <CloseButton onClick={closeModal}>
            <X size={18} />
          </CloseButton>
        </ModalBody>
      </Content>
    </Overlay>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  handleModal: PropTypes.node,
}.isRequired;

export default Modal;
