import styled from 'styled-components';

import Hero from '@/assets/hero.webp';

export const Container = styled.section`
  align-items: center;
  background: url(${Hero}) no-repeat;
  background-position: 77% -95px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  justify-content: space-between;
  min-height: 100vh;
  position: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-position: center;
    gap: 10vh;
    justify-content: unset;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 0 2rem;
  }
`;

export const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  justify-content: end;
  margin-bottom: 1rem;
  max-width: 1070px;
  padding: 0 2rem 2rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 5vh;
    justify-content: start;
    margin-bottom: 0;
  }
`;
