import React from 'react';

import ContactForm from '@/components/organisms/ContactForm';
import EventSchedule from '@/components/organisms/EventsSchedule';
import Footer from '@/components/organisms/Footer';
import HeroSection from '@/components/organisms/HeroSection';
import HowWeDoIt from '@/components/organisms/HowWeDoIt';
import InfluencersSection from '@/components/organisms/InfluencersSection';
import OurBrands from '@/components/organisms/OurBrands';
import OurClients from '@/components/organisms/OurClients';
import WhatWeDo from '@/components/organisms/WhatWeDo';
import i18n from '@/translate/i18n';
import VpagSection from '@/components/organisms/VpagSection';

function Home() {
  i18n.changeLanguage('en');
  return (
    <>
      <HeroSection />
      <WhatWeDo />
      <HowWeDoIt />
      <InfluencersSection />
      <VpagSection />
      <OurBrands />
      <OurClients />
      <EventSchedule />
      <ContactForm />
      <Footer />
    </>
  );
}

export default Home;
