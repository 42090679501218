import React from 'react';

import Address from '@/components/molecules/Address';
import i18n from '@/translate/i18n';
import useScreenWidth from '@/components/hooks/useScreeWidth';
import { FlexDiv } from '@/components/atoms/Containers/styles';
import { ReactComponent as GooglePartner } from '@/assets/google-partner.svg';
import { ReactComponent as Logo } from '@/assets/eightroom-logo.svg';
import { ReactComponent as MinimumAgeIcon } from '@/assets/icons/minimum-age.svg';
import {
  ExternalLink,
  NavLink,
  Text,
} from '@/components/atoms/Typography/styles';

import { Container, ContentWrapper, NavLinkWrapper, Section } from './styles';

function Footer() {
  const { isDesktop } = useScreenWidth();

  return (
    <>
      <Container>
        <Address />
        <ContentWrapper>
          <FlexDiv direction="column" align="flex-end">
            <Logo />
            <NavLink to="/minimum-age" hideInMobile>
              <MinimumAgeIcon />
            </NavLink>
          </FlexDiv>
          <FlexDiv direction={isDesktop ? 'row' : 'column-reverse'}>
            <GooglePartner />
            <NavLinkWrapper>
              <NavLink to="/terms-and-conditions" size="lg" color="light">
                {i18n.t('footer.termsAndConditions')}
              </NavLink>
              <NavLink to="/affiliate" size="lg" color="light">
                {i18n.t('footer.affiliate')}
              </NavLink>
              <NavLink to="/responsible-gaming" size="lg" color="light">
                {i18n.t('footer.responsibleGaming')}
              </NavLink>
              <NavLink to="/privacy-policy" size="lg" color="light">
                {i18n.t('footer.privacyPolicy')}
              </NavLink>
              <ExternalLink href="https://vpag.com" target="_blank">
                {i18n.t('footer.paymentSolution')}
              </ExternalLink>
              <NavLink to="/cookie-policy" size="lg" color="light">
                {i18n.t('footer.cookiesPolicy')}
              </NavLink>
              <NavLink to="/minimum-age" hideInDesktop>
                <MinimumAgeIcon />
              </NavLink>
            </NavLinkWrapper>
          </FlexDiv>
        </ContentWrapper>
      </Container>
      <Section>
        <Text color="light" size="sm">
          {i18n.t('footer.allRightsReserved')}
        </Text>
      </Section>
    </>
  );
}

export default Footer;
