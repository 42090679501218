import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.light};
  min-height: 55vh;
  padding: 2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 6rem 0;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    text-align: left;
  }
`;

export const GetToKnowButton = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  font: ${({ theme }) =>
    `600 ${theme.font.size.md} ${theme.font.family.primary}`};
  gap: 0.5rem;
  padding: 0.5rem 1rem;

  div {
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    display: flex;
    height: 25px;
    justify-content: center;
    width: 25px;
  }

  &:hover {
    filter: brightness(0.7);
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    width: 45%;

    .section-title {
      max-width: 250px;
    }
  }
`;
