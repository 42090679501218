import styled, { css } from 'styled-components';

export const DefaultContainer = styled.div`
  align-items: center;
  background-color: ${({ theme, bgColor }) =>
    theme.colors.bg[bgColor ?? 'primary']};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FlexDiv = styled.div`
  ${({ align, direction, justify, gap, width }) => css`
    align-items: ${align ?? 'center'};
    display: flex;
    flex-direction: ${direction ?? 'row'};
    gap: ${gap ?? '1rem'};
    justify-content: ${justify ?? 'start'};
    width: ${width ?? 'auto'};
  `}
`;
