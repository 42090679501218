import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import MenuButton from '@/components/atoms/Button/MenuButton';
import { NavLink } from '@/components/atoms/Typography/styles';
import MobileMenu from '@/components/organisms/MobileMenu';
import NavLinks from '@/components/molecules/NavLinks';
import useOnClickOutside from '@/components/hooks/useClickOutside';
import i18n from '@/translate/i18n';

import { Container, ContentWrapper, Logo, NavWrapper } from './styles';

function Header({ bg }) {
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);
  useOnClickOutside(ref, setShowMenu);

  useEffect(() => {
    const timer = setTimeout(() => {
      const currentLocation = window.location.href;
      const hasHashParam = currentLocation.includes('#');

      if (hasHashParam) {
        const paramId = `${currentLocation.substring(
          currentLocation.indexOf('#') + 1,
        )}`;
        const element = document.getElementById(paramId);
        if (element) {
          element.scrollIntoView();
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container bg={bg} ref={ref}>
      <ContentWrapper>
        <Link to="/">
          <Logo />
        </Link>

        <NavLink to="/affiliate">{i18n.t('header.affiliate')}</NavLink>
        <div style={{ display: 'flex' }}>
          <NavWrapper>
            <NavLinks />
          </NavWrapper>
        </div>
      </ContentWrapper>

      <MenuButton onClick={() => setShowMenu((state) => !state)} />
      <MobileMenu
        showMenu={showMenu}
        setShowMenu={() => setShowMenu((state) => !state)}
      />
    </Container>
  );
}

Header.propTypes = {
  bg: PropTypes.string,
};
export default Header;
