import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  padding: 0rem 0 3rem;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-items: center;
  max-width: 1070px;
  padding: 3rem 1rem 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Section = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.tertiary};
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100%;
`;

export const NavLinkWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    gap: 1;
    grid-template-columns: repeat(2, auto);
    justify-items: start;
    row-gap: 0.5rem;
  }
`;
