import React from 'react';

import ArrowDown from '@/components/atoms/ArrowDown';

import { HeroTitle, Text } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';

import { Container, Step, TypeOfRevenue } from './styles';

const STEPS = [
  'influencersWorkSteps.stepA',
  'influencersWorkSteps.stepB',
  'influencersWorkSteps.stepC',
];

const REWARDS = [
  'influencersWorkSteps.rewardA',
  'influencersWorkSteps.rewardB',
  'influencersWorkSteps.rewardC',
  'influencersWorkSteps.rewardD',
];

function InfluencersWorkSteps() {
  return (
    <Container color="light" id="howTheyWork">
      <div className="wrapper">
        <HeroTitle color="dark">
          {i18n.t('influencersWorkSteps.title')}
        </HeroTitle>
        <div className="flex-column">
          {STEPS.map((item, index) => (
            <Step key={item}>
              <div className="identifier">
                <Text weight="bold" size="4xl">
                  {index + 1}
                </Text>
              </div>
              <div className="content">
                <Text weight="500">{i18n.t(item)}</Text>
              </div>
            </Step>
          ))}
          <TypeOfRevenue>
            <div className="blackbox">
              <Text size="lg" color="primary" weight="500">
                {i18n.t('influencersWorkSteps.rewardMessage')}
              </Text>
            </div>
            <div className="whitebox">
              {REWARDS.map((item) => (
                <div className="box">
                  <Text>●</Text>
                  <Text weight="bold" size="xl" key={item}>
                    {i18n.t(item)}
                  </Text>
                </div>
              ))}
            </div>
          </TypeOfRevenue>
        </div>
        <ArrowDown url="#getMore" />
      </div>
    </Container>
  );
}

export default InfluencersWorkSteps;
