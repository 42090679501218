import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Image, Wrapper } from './styles';

function BrandPreview({ slug, width, hideInMobile, hideInDesktop }) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [slug]);

  return (
    <Wrapper hideInMobile={hideInMobile} hideInDesktop={hideInDesktop}>
      {!isError ? (
        <Image
          src={`/images/brandPreview/${slug}.png`}
          width={width}
          height={width}
          onError={setIsError}
        />
      ) : (
        <Image
          src="/images/brandPreview/brand-generic.png"
          width={width}
          height={width}
          onError={setIsError}
        />
      )}
    </Wrapper>
  );
}

BrandPreview.propTypes = {
  hideInDesktop: PropTypes.bool,
  hideInMobile: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  width: PropTypes.string,
}.isRequired;

export default BrandPreview;
