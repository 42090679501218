import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '@/styles/global';
import ScrollToTop from '@/components/atoms/ScrollToTop';
import main from '@/styles/themes/main';

import {
  AboutUs,
  Affiliate,
  CookiePolicy,
  Home,
  MinimumAge,
  PrivacyPolicy,
  ResponsibleGaming,
  TermsAndConditions,
} from '@/components/pages';

function App() {
  return (
    <ThemeProvider theme={main}>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/affiliate" component={Affiliate} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/cookie-policy" component={CookiePolicy} />
        <Route exact path="/responsible-gaming" component={ResponsibleGaming} />
        <Route exact path="/minimum-age" component={MinimumAge} />
        <Route exact path="/minimum-age" component={MinimumAge} />
        <Route exact path="/about-us" component={AboutUs} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
