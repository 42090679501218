import styled from 'styled-components';

export const Container = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.light};
  display: flex;
  flex-direction: column;
  min-height: 70vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0;
  max-width: 1070px;
  padding: 0 1rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
  }
`;

export const TextWrapper = styled.div`
  padding: 0.8rem 0;

  &:first-of-type {
    margin-top: 1rem;
  }

  a {
    color: ${({ theme }) => theme.colors.link.primary};
    font: ${({ theme }) => theme.font.family.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li {
    list-style-position: inside;
    margin-bottom: 0.5rem;
    padding-left: 2em;
    text-indent: -1.3em;
  }

  p {
    text-indent: 2em;
  }
`;

export const TextContentWrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 3rem;
`;
