import React from 'react';

import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';
import data from './data';
import {
  Container,
  ContentWrapper,
  MerchantWrapper,
  TextWrapper,
} from './styles';

function OurClients() {
  return (
    <Container>
      <ContentWrapper>
        <TextWrapper>
          <div>
            <Text color="light" size="lg" id="clients">
              {i18n.t('ourClients.title')}
            </Text>
            <Text color="light" size="2xl" weight="700">
              {i18n.t('ourClients.description')}
            </Text>
          </div>
          <Text color="light">{i18n.t('ourClients.content')}</Text>
        </TextWrapper>

        <Text size="4xl" weight="700" color="primary">
          {i18n.t('ourClients.whoTrustUs')}
        </Text>
        <Text />
        <MerchantWrapper>
          {data.map(({ slug, image }) => (
            <img src={image} alt="slug" key={slug} />
          ))}
        </MerchantWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default OurClients;
