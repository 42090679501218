import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { ReactComponent as TitleImageArrowDown } from '@/assets/how-they-work-down.svg';
import { ReactComponent as TitleImageArrowRight } from '@/assets/how-they-work-left.svg';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  min-height: 55vh;
  padding: 25rem 0 6rem;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 10rem 0 6rem;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 2rem;

  & > div p {
    max-width: 600px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    & > div p {
      max-width: 300px;
    }
  }
`;

export const MobileImage = styled.img`
  max-width: 90vw;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const DesktopImage = styled.img`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 400px;
    max-width: 90vw;
    display: block;
  }
`;

export const ItemsWrapper = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  max-width: 380px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 450px;
    flex-direction: row;
  }
`;

export const TitleImageDesktop = styled(TitleImageArrowRight)`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    max-width: 350px;
  }
`;

export const TitleImageMobile = styled(TitleImageArrowDown)`
  margin: 2rem 0;
  max-width: 350px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
