import React from 'react';

import Header from '@/components/organisms/Header';
import i18n from '@/translate/i18n';
import { ReactComponent as LatamFlags } from '@/assets/flags.svg';

import { HeroTitle, Text } from '@/components/atoms/Typography/styles';

import ArrowDown from '@/components/atoms/ArrowDown';

import { AffiliateContainer, ObjetiveWrapper, Wrapper } from './styles';

function AffiliateHeroSection() {
  return (
    <AffiliateContainer>
      <Header bg="transparent" />
      <Wrapper>
        <Text size="lg" color="light">
          {i18n.t('hero.affiliateMarketing')}
        </Text>
        <HeroTitle>{i18n.t('affiliateHero.title')}</HeroTitle>
        <LatamFlags />
      </Wrapper>
      <ObjetiveWrapper>
        <div className="highlight">
          <Text color="light" size="lg">
            {i18n.t('affiliateHero.goal')}
          </Text>
        </div>
        <Text color="light" size="lg">
          {i18n.t('affiliateHero.goalA')}
        </Text>
        <Text color="light" size="lg">
          {i18n.t('affiliateHero.goalB')}
        </Text>
        <Text color="light" size="lg">
          {i18n.t('affiliateHero.goalC')}
        </Text>
      </ObjetiveWrapper>

      <ArrowDown url="#trust" />
    </AffiliateContainer>
  );
}

export default AffiliateHeroSection;
