export default [
  {
    id: 1,
    address: 'UY - Ruta 8, Kilómetro 17.500',
    complement: 'Zona Franca, Zonamerica\nEdificio Beta 4, Oficina 006',
    state: 'Montevideo, Uruguay',
    flag: 'uy',
  },
  {
    id: 2,
    address: 'Rua Augusto Meyer, nº 40',
    complement: 'Sala 504\nPorto Alegre',
    state: 'Rio Grande do Sul, Brasil',
    flag: 'br',
  },
];
