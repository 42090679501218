import styled from 'styled-components';
import { SectionContainer } from '@/components/atoms/SectionContainer/styles';

export const Container = styled(SectionContainer)`
  gap: 2rem;
  padding: 3em 0 3rem 0;
  width: 100%;

  #hubspotForm {
    max-width: 1070px;
    padding: 0 2rem;
    width: 100% !important;
  }

  & > p {
    font-weight: 700;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    & > p {
      max-width: 1070px;
      text-align: center;
    }
  }
`;
