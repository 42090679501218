import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { ReactComponent as Logo } from '@/assets/eightroom-dark.svg';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.light};
  min-height: 55vh;
  padding: 3rem 0;
  position: relative;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;

  p {
    max-width: 800px;
  }
`;

export const BrandWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 0.5rem;
  width: 100%;

  p {
    max-width: 340px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    margin-top: 3rem;
    max-width: 80%;
    text-align: left;

    p {
      max-width: none;
      text-align: ${({ reverse }) => (reverse ? 'left' : 'right')};
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div {
    img {
      margin-left: 0.3rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    justify-content: space-between;
    width: 100%;
  }
`;

export const BrandContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
  }
`;

export const MainLogo = styled(Logo)`
  max-width: 120px;
`;
