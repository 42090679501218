import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { Text } from '@/components/atoms/Typography/styles';

import BrandLogo from '@/components/atoms/BrandLogo';
import BrandPreview from '@/components/atoms/BrandPreview';
import ExternalLinkButton from '@/components/atoms/Button/ExternalLinkButton';
import i18n from '@/translate/i18n';

import {
  BrandContent,
  BrandWrapper,
  Container,
  ContentWrapper,
  LogoWrapper,
  MainLogo,
} from './styles';

function OurBrands() {
  return (
    <Container>
      <MainLogo />
      <ContentWrapper>
        <Text size="4xl" weight="700" id="brands">
          {i18n.t('ourBrands.title')}
        </Text>
        <Text size="lg">{i18n.t('ourBrands.description')}</Text>

        {i18n
          .t('ourBrands.brands', {
            returnObjects: true,
          })
          .map(({ slug, content, url, flags }, idx) => (
            <BrandWrapper key={slug} reverse={idx % 2 === 0}>
              <BrandContent>
                <LogoWrapper reverse={idx % 2 === 0}>
                  <div>
                    {flags.map((flag) => (
                      <CircleFlag countryCode={flag} height={40} key={flag} />
                    ))}
                  </div>
                  <BrandLogo slug={slug} />
                </LogoWrapper>
                <BrandPreview slug={slug} hideInDesktop />
                <Text>{content}</Text>
                <ExternalLinkButton url={url} />
              </BrandContent>
              <BrandPreview slug={slug} hideInMobile />
            </BrandWrapper>
          ))}
      </ContentWrapper>
    </Container>
  );
}

export default OurBrands;
