import React from 'react';
import PropTypes from 'prop-types';
import { ArrowUpRight } from 'phosphor-react';

import { Button, HashLinkButton } from './styles';

function ExternalLinkButton({
  url,
  label,
  target,
  isHashLink,
  className = '',
}) {
  const LinkType = isHashLink ? HashLinkButton : Button;
  return (
    <LinkType
      href={url}
      to={url}
      target={target ?? '_blank'}
      className={className}
    >
      {label ?? url?.replace('https://', '')}
      <ArrowUpRight size={25} weight="bold" />
    </LinkType>
  );
}

ExternalLinkButton.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  isHashLink: PropTypes.bool,
  target: PropTypes.string,
  className: PropTypes.string,
};

export default ExternalLinkButton;
