import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1070px;
  padding: 3rem 0;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: left;

    p {
      align-self: start;
    }
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    text-align: left;
  }
`;

export const ContactWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.primary};
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
  padding: 4rem 0;
  width: 95%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-radius: 10rem;
    flex-direction: row;
    justify-content: space-around;
    padding: 4rem 4rem;

    p {
      max-width: 50%;
    }
  }
`;
