import React from 'react';

import data from '../OurClients/data';
import { ContentWrapper } from '../OurClients/styles';
import { HeroTitle } from '@/components/atoms/Typography/styles';
import ArrowDown from '@/components/atoms/ArrowDown';

import { MerchantsBox, Wrapper } from './styles';
import i18n from '@/translate/i18n';

function TrustSection() {
  return (
    <Wrapper id="trust">
      <ContentWrapper>
        <HeroTitle color="dark">{i18n.t('trustSection.title')}</HeroTitle>
        <MerchantsBox className="merchants">
          {data.map(({ slug, image }) => (
            <img src={image} alt="slug" key={slug} />
          ))}
        </MerchantsBox>
      </ContentWrapper>
      <ArrowDown color="shadow" url="#howWeWork" />
    </Wrapper>
  );
}

export default TrustSection;
