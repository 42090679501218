import styled, { css } from 'styled-components';

export const Button = styled.button`
  ${({ theme, color = 'dark' }) => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.shadow};
    border-radius: 8px;
    color: ${theme.colors.info[color]};
    font: ${`${theme.font.size['2xl']} ${theme.font.family.primary}`};
    height: 56px;
    padding: 10px;
    width: 280px;

    &:hover {
      border: 2px solid ${theme.colors.shadow};
    }
  `}
`;
