import styled from 'styled-components';
import { SectionContainer } from '@/components/atoms/SectionContainer/styles';

export const Container = styled(SectionContainer)`
  align-items: center;
  min-height: 80vh !important;

  .kpi {
    font-size: 2.5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 900px !important;
    h1 {
      padding-right: 30rem;
      line-height: 64px;
    }

    .kpi {
      font-size: 1.86rem;
    }

    .less-width {
      padding-right: 3.5rem;
    }
  }
`;
