import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { ReactComponent as MainLogo } from '@/assets/eightroom-logo.svg';
import { hexToRGBA } from '@/styles/colors';

export const Container = styled(DefaultContainer)`
  align-items: center;
  background-color: ${({ theme }) => hexToRGBA(theme.colors.bg.dark, 1)};
  display: flex;
  min-height: 58px;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-color: ${({ theme, bg }) => bg ?? theme.colors.bg.dark};
    padding: 2rem 0;
  }
`;

export const ContentWrapper = styled.header`
  align-items: center;
  align-self: start;
  display: flex;
  gap: 0.5rem;
  height: 100%;
  justify-content: space-between;
  max-width: 1070px;
  min-height: 58px;
  padding-left: 2rem;
  width: 80%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-self: center;
  }
`;

export const Logo = styled(MainLogo)`
  cursor: pointer;
  width: 90px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 120px;
  }
`;

export const NavWrapper = styled.div`
  display: none;
  margin-right: 3rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;
