import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeft } from 'phosphor-react';
import { useHistory } from 'react-router-dom';

import { Button } from './styles';
import { Text } from '../../Typography/styles';
import i18n from '@/translate/i18n';

function ReturnButton({ onClick, label }) {
  const history = useHistory();
  return (
    <Button type="button" onClick={onClick ?? (() => history.goBack())}>
      <ArrowLeft size={24} weight="bold" />
      <Text color="white" weight="400">
        {label && i18n.t(label)}
      </Text>
    </Button>
  );
}

ReturnButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

export default ReturnButton;
