import React from 'react';

import ArrowDown from '@/components/atoms/ArrowDown';
import influencers from '@/assets/influencers.png';
import { HeroTitle, Text } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';

import { Container, Influencers } from './styles';

function WorkSteps() {
  return (
    <Container id="affiliateInfluencers">
      <div className="wrapper">
        <HeroTitle>{i18n.t('influencers.title')}</HeroTitle>
        <div className="flex-column">
          <Text color="light" size="md">
            {i18n.t('influencers.content')}
          </Text>
          <Influencers src={influencers} />
        </div>
        <ArrowDown url="#howTheyWork" />
      </div>
    </Container>
  );
}

export default WorkSteps;
