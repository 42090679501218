import React from 'react';
import { ArrowUpRight } from 'phosphor-react';

import i18n from '@/translate/i18n';
import { HashLink, Text } from '@/components/atoms/Typography/styles';

import {
  Container,
  ContentWrapper,
  GetToKnowButton,
  TextWrapper,
} from './styles';

function WhatWeDo() {
  return (
    <Container>
      <ContentWrapper>
        <TextWrapper>
          <Text
            size="5xl"
            weight="700"
            color="primary"
            className="section-title"
          >
            {i18n.t('whatWeDo.title')}
          </Text>
          <Text size="2xl" weight="700">
            {i18n.t('whatWeDo.description')}
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text size="lg">{i18n.t('whatWeDo.content')}</Text>
          <HashLink to="#clients">
            <GetToKnowButton type="button">
              {i18n.t('whatWeDo.getToKnow')}
              <div>
                <ArrowUpRight weight="bold" />
              </div>
            </GetToKnowButton>
          </HashLink>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default WhatWeDo;
