import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';
import { Button } from '@/components/atoms/Button/ExternalLinkButton/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.light};
  min-height: 55vh;
  padding: 4rem 0;
  position: relative;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 1270px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;
`;

export const ItemsWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 374px;

  a {
    text-decoration: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
`;

export const Item = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 374px;
  justify-content: space-between;
  margin-bottom: 1rem;

  img {
    width: 200px;
    height: 200px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 0;
  }
`;

export const ExternalButton = styled(Button)`
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.size.sm};
`;
