import React from 'react';
import { CircleFlag } from 'react-circle-flags';

import data from './data';
import { Text } from '@/components/atoms/Typography/styles';
import { AddressWrapper, Container, ContentWrapper } from './styles';

function Address() {
  return (
    <Container>
      <ContentWrapper>
        {data.map(({ id, address, complement, state, flag }) => (
          <AddressWrapper key={id}>
            <CircleFlag countryCode={flag} height={40} />
            <div>
              <Text color="light" size="lg" weight="600">
                {address}
              </Text>
              <Text color="light">{complement}</Text>
              <Text color="light" weight="600">
                {state}
              </Text>
            </div>
          </AddressWrapper>
        ))}
      </ContentWrapper>
    </Container>
  );
}

export default Address;
