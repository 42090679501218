import styled from 'styled-components';

export const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  font: ${({ theme }) =>
    `${theme.font.size.base} ${theme.font.family.primary}`};
  gap: 0.6rem;
  left: 0;
  position: absolute;
  top: 8vh;

  &:hover {
    color: ${({ theme }) => theme.colors.info.dark};
  }
`;
