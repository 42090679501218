import { HashLink } from 'react-router-hash-link';
import styled, { css } from 'styled-components';

const styles = css`
  align-items: center;
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.info.dark};
  color: ${({ theme }) => theme.colors.info.dark};
  display: flex;
  font: ${({ theme }) => `${theme.font.size.md} ${theme.font.family.primary}`};
  gap: 0.5rem;
  padding: 0.3em 1.5rem;
  text-decoration: none;
`;

export const Button = styled.a`
  ${styles}
`;

export const HashLinkButton = styled(HashLink)`
  ${styles}
`;
