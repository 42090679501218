import i18n from '@/translate/i18n';

import money from '@/assets/icons/money-arrows.png';
import transfer from '@/assets/icons/transfer.png';
import infinity from '@/assets/icons/infinity.png';
import bank from '@/assets/icons/bank.png';
import brazil from '@/assets/icons/brazil.png';

export default [
  {
    title: i18n.t('vpagSection.crypto'),
    icon: money,
  },
  {
    title: i18n.t('vpagSection.iban'),
    icon: transfer,
  },
  {
    title: i18n.t('vpagSection.limits'),
    icon: infinity,
  },
  {
    title: i18n.t('vpagSection.payment'),
    icon: bank,
  },
  {
    title: i18n.t('vpagSection.regualtionMarket'),
    icon: brazil,
  },
];
