import { useState } from 'react';

import vpag from '@/styles/themes/main';

const useScreenWidth = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => setDeviceWidth(window.innerWidth));

  const isMobile = deviceWidth <= vpag.breakpoints.sm.replace('px', '');
  const isTablet =
    deviceWidth > vpag.breakpoints.sm.replace('px', '') &&
    deviceWidth <= vpag.breakpoints.md.replace('px', '');
  const isDesktop = deviceWidth >= vpag.breakpoints.md.replace('px', '');

  return { deviceWidth, isMobile, isTablet, isDesktop };
};

export default useScreenWidth;
