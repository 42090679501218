import React from 'react';

import Article from '@/components/molecules/Article';
import ArticleHeader from '@/components/molecules/ArticleHeader';
import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import i18n from '@/translate/i18n';
import { HashLink, Text } from '@/components/atoms/Typography/styles';
import {
  Container,
  ContentWrapper,
  TextWrapper,
} from '@/components/molecules/Article/styles';

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <Container>
        <ArticleHeader title={i18n.t('privacyPolicy.title')} />
        <ContentWrapper>
          <Article
            textContent={i18n.t('privacyPolicy.content', {
              returnObjects: true,
            })}
            append={
              <div>
                <Text size="2xl">
                  {i18n.t('privacyPolicy.contactSection.title')}
                </Text>
                <TextWrapper>
                  <Text variant="secondary" size="md">
                    {i18n.t('privacyPolicy.contactSection.paragraph')}
                    <HashLink smooth to="/#contact">
                      {i18n.t('privacyPolicy.contactSection.linkTo')}
                    </HashLink>
                    {i18n.t('privacyPolicy.contactSection.after')}
                  </Text>
                </TextWrapper>
              </div>
            }
          />
        </ContentWrapper>
      </Container>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
