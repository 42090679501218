import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  max-width: 1060px;
  min-height: 45vh;
  position: relative;
  text-align: center;
  width: 100%;

  p {
    max-width: 80%;
  }
`;
