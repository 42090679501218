import React, { useState } from 'react';

import ArticleHeader from '@/components/molecules/ArticleHeader';
import CollapseButton from '@/components/atoms/Button/CollapseButton';
import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import i18n from '@/translate/i18n';
import { HashLink, Text } from '@/components/atoms/Typography/styles';
import {
  Container,
  ContentWrapper,
} from '@/components/molecules/Article/styles';

import { TextContentWrapper } from './styles';

function TermsAndConditions() {
  const [openedTopics, setOpenedTopics] = useState([]);

  const isTopicOpen = (title) => !!openedTopics.find((el) => el === title);

  const toggleTopic = (title) => {
    if (isTopicOpen(title)) {
      setOpenedTopics((state) => state.filter((el) => el !== title));
    } else {
      setOpenedTopics((state) => [...state, title]);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <ArticleHeader title={i18n.t('termsAndConditions.title')} />

        <ContentWrapper>
          <div>
            {i18n
              .t('termsAndConditions.content', { returnObjects: true })
              .map((el, i) => {
                const topicIndex = i + 1;
                const formattedIndex =
                  topicIndex >= 10 ? topicIndex : `0${topicIndex}`;
                return (
                  <TextContentWrapper
                    key={el.title}
                    isOpen={isTopicOpen(el.title)}
                  >
                    <CollapseButton
                      isOpen={isTopicOpen(el.title)}
                      onClick={() => toggleTopic(el.title)}
                    />
                    <Text
                      size="3xl"
                      variant="secondary"
                      onClick={() => toggleTopic(el.title)}
                      style={{ cursor: 'pointer' }}
                    >
                      {`${formattedIndex}. ${el.title}`}
                    </Text>
                    <div>
                      {el?.renderAsHtml ? (
                        <Text
                          variant="secondary"
                          size="md"
                          dangerouslySetInnerHTML={{
                            __html: el?.content,
                          }}
                        />
                      ) : (
                        <Text variant="secondary" size="md">
                          {el.content}
                        </Text>
                      )}
                    </div>
                  </TextContentWrapper>
                );
              })}
            <TextContentWrapper isOpen={isTopicOpen('contact')}>
              <CollapseButton
                isOpen={isTopicOpen('contact')}
                onClick={() => toggleTopic('contact')}
              />
              <Text
                size="3xl"
                variant="secondary"
                onClick={() => toggleTopic('contact')}
                style={{ cursor: 'pointer' }}
              >
                {`07. ${i18n.t('termsAndConditions.contactSection.title')}`}
              </Text>
              <div>
                <Text variant="secondary" size="md">
                  {i18n.t('termsAndConditions.contactSection.content')}
                  <HashLink smooth to="/#contact">
                    {i18n.t('termsAndConditions.contactSection.linkTo')}
                  </HashLink>
                </Text>
              </div>
            </TextContentWrapper>
          </div>
        </ContentWrapper>
      </Container>

      <Footer />
    </>
  );
}

export default TermsAndConditions;
