import React from 'react';

import { Trans } from 'react-i18next';
import ArrowDown from '@/components/atoms/ArrowDown';

import { HeroTitle, Text } from '@/components/atoms/Typography/styles';

import { Container } from './styles';
import i18n from '@/translate/i18n';

function HowWeWork() {
  return (
    <Container color="light" id="howWeWork">
      <div className="wrapper">
        <HeroTitle color="dark">{i18n.t('howWeWork.title')}</HeroTitle>
        <div className="flex-column">
          <Text color="shadow" size="lg" className="less-width">
            {i18n.t('howWeWork.goalA')}
          </Text>
          <Text color="shadow" size="lg" className="less-width">
            {i18n.t('howWeWork.goalB')}
          </Text>
          <div className="highlight">
            <Text color="shadow" size="lg">
              {i18n.t('howWeWork.kpiTitle')}
            </Text>
          </div>
          <div className="flex-column">
            <Text size="2xl" weight="700" className="kpi">
              <Trans
                i18n={i18n}
                i18nKey="howWeWork.kpiA"
                components={[<span className="highlight-text" />]}
              />
            </Text>
            <Text size="2xl" weight="700" className="kpi">
              <Trans
                i18n={i18n}
                i18nKey="howWeWork.kpiB"
                components={[<span className="highlight-text" />]}
              />
            </Text>
            <Text size="2xl" weight="700" className="kpi">
              <Trans
                i18n={i18n}
                i18nKey="howWeWork.kpiC"
                components={[<span className="highlight-text" />]}
              />
            </Text>
          </div>
        </div>
        <ArrowDown url="#affiliateInfluencers" />
      </div>
    </Container>
  );
}

export default HowWeWork;
