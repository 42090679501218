import React, { useEffect } from 'react';

import { Container, ImgImage, StackContainer } from './styles';

const HubspotContactForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '20494613',
          formId: '348f0155-20b9-412b-b33f-1e3c4b5e029e',
          target: '#hubspotForm',
        });
      }
    });
  }, []);

  return (
    <Container id="contact">
      <StackContainer>
        <ImgImage />
        <div id="hubspotForm" />
      </StackContainer>
      <script
        charset="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/v2.js"
      />
    </Container>
  );
};

export default HubspotContactForm;
