import styled from 'styled-components';

import { ReactComponent as Message } from '@/assets/areyouready.svg';
import {
  DefaultContainer,
  FlexDiv,
} from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.light};
  display: flex;
  padding: 5.5rem 3rem 5rem;

  #hubspotForm {
    width: 90%;
  }

  & > p {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 4.5rem 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    #hubspotForm {
      width: 100%;
    }
  }
`;

export const ImgImage = styled(Message)``;

export const StackContainer = styled(FlexDiv)`
  align-items: flex-start;
  gap: 3rem;

  svg {
    height: 250px;
    width: 500px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    flex-direction: column;

    svg {
      width: 300px;
    }
  }
`;
