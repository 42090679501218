import styled from 'styled-components';

export const Button = styled.div`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 1.5rem;
  top: 0.9rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;
