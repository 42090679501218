import React from 'react';

import ArrowDown from '@/components/atoms/ArrowDown';
import cardImage from '@/assets/get-more.png';
import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';

import { ContactButton, Container, ContentWrapper, Image } from './styles';

function GetMoreSection() {
  return (
    <Container id="getMore">
      <ContentWrapper>
        <div>
          <Text size="5xl" color="light" weight="800" variant="primary">
            {i18n.t('getMore.title')}
          </Text>
          <Text color="light" size="md">
            {i18n.t('getMore.content')}
          </Text>
        </div>
        <Image src={cardImage} />
      </ContentWrapper>
      <ContactButton>{i18n.t('getMore.button')}</ContactButton>
      <ArrowDown url="#form" />
    </Container>
  );
}

export default GetMoreSection;
