export default [
  { slug: 'bet365', image: '/images/merchants/bet365.svg' },
  { slug: 'betano', image: '/images/merchants/betano.png' },
  { slug: 'betfair', image: '/images/merchants/betfair.svg' },
  { slug: 'stake', image: '/images/merchants/stake.svg' },
  { slug: '1xbet', image: '/images/merchants/1xbet.svg' },
  { slug: 'estrela-bet', image: '/images/merchants/sportingbet.png' },
  { slug: 'estrela-bet', image: '/images/merchants/estrelabet.png' },
  { slug: 'parimatch', image: '/images/merchants/parimatch.svg' },
  { slug: 'poker-stars', image: '/images/merchants/poker-stars.png' },
];
