import styled from 'styled-components';
import { SectionContainer } from '@/components/atoms/SectionContainer/styles';

export const Container = styled(SectionContainer)`
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    h1 {
      max-width: 20rem;
    }

    p {
      max-width: 32rem;
    }
  }
`;

export const Step = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10rem;
  display: grid;
  gap: 2rem;
  height: 8rem;
  padding-left: 8rem;
  place-content: center;
  position: relative;
  width: 100%;

  .content {
    margin: auto;
    max-width: 90%;

    p {
      font-size: 0.9rem !important;
    }
  }

  .identifier {
    background-color: ${({ theme }) => theme.colors.light};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    display: grid;
    height: 8rem;
    left: -2px;
    place-content: center;
    position: absolute;
    top: -2px;
    width: 8rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 7rem;
    margin-left: 5rem;
    padding-left: 4rem;
    width: 80%;

    .content {
      max-width: 70%;
    }

    .identifier {
      height: 7rem;
      width: 7rem;
    }
  }
`;

export const TypeOfRevenue = styled.div`
  display: flex;
  flex-direction: column;
  height: 20rem;
  margin-top: 2rem;
  width: 100%;

  .blackbox {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.dark};
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    display: flex;
    flex: 0.32;
    justify-content: center;
    padding: 0.5rem;

    p {
      max-width: 80%;
    }
  }

  .whitebox {
    align-items: center;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    border-left: 2px solid ${({ theme }) => theme.colors.primary};
    border-right: 2px solid ${({ theme }) => theme.colors.primary};
    display: grid;
    flex: 0.68;
    grid-template-columns: 1fr 1fr;
    padding-left: 2rem;

    .box {
      align-items: center;
      display: flex;
      gap: 0.2rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    height: 7rem;

    .blackbox {
      border-bottom-left-radius: 5rem;
      border-top-left-radius: 5rem;
      border-top-right-radius: 0;
      padding-left: 2rem;
    }

    .whitebox {
      border-bottom-left-radius: 0;
      border-left: none;
      border-top-right-radius: 1rem;
      border-top: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }
`;
