import React, { useEffect } from 'react';

import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';

import { Container } from './styles';

function AffiliateForm() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '22595455',
          formId: '03679459-d02f-423a-99a9-31a988480190',
          target: '#hubspotForm',
        });
      }
    });
  }, []);

  return (
    <Container color="light" id="form">
      <Text size="5xl">{i18n.t('affiliateForm.title')}</Text>
      <div id="hubspotForm" />
      <script
        charset="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/v2.js"
      />
    </Container>
  );
}

export default AffiliateForm;
