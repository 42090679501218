import styled from 'styled-components';

export const ContactButton = styled.button`
  background-color: transparent;
  border-radius: 15px;
  border: 2px solid ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.info.light};
  font: ${({ theme }) => `${theme.font.size.xl} ${theme.font.family.primary}`};
  height: 53px;
  width: 160px;

  &:hover {
    filter: brightness(0.6);
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;
