import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  min-height: 55vh;
  padding: 2rem 0 25rem;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 6rem 0;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 5rem;
    text-align: left;
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  div {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: start;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;

    div {
      width: 45%;
    }
  }
`;

export const InfoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.light};
  border-radius: 12rem;
  bottom: -22rem;
  display: flex;
  flex-direction: column;
  height: 620px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  width: 80vw;
  z-index: 3;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    bottom: -8rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 5rem;
    max-height: 240px;
    max-width: 1070px;
    position: absolute;
    width: 90vw;
  }
`;

export const Section = styled.div`
  align-items: center;
  background-color: ${({ color }) => color};
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 2.6rem;
  text-align: center !important;
`;
