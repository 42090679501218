import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { ArrowLeft, ArrowRight } from 'phosphor-react';

import CarouselCard from '@/components/molecules/CarouselCard';
import ExternalLinkButton from '@/components/atoms/Button/ExternalLinkButton';
import i18n from '@/translate/i18n';
import image from '@/assets/wallet-with-card.png';
import { ReactComponent as VpagLogo } from '@/assets/vpag-logo-black.svg';
import { Text } from '@/components/atoms/Typography/styles';

import {
  Container,
  ContentWrapper,
  SectionImage,
  SwiperWrapper,
  TextWrapper,
} from './styles';

import data from './data';

import 'swiper/css';
import 'swiper/css/pagination';

const SPACE_BETWEEN_CARDS = 20;

const SWIPER_BREAKPOINTS = {
  0: {
    slidesPerView: 0.8,
  },

  375: {
    slidesPerView: 1,
  },

  550: {
    slidesPerView: 1.5,
  },

  700: {
    slidesPerView: 2,
  },

  800: {
    slidesPerView: 2.5,
  },

  1024: {
    slidesPerView: 3,
  },
};

function VpagSection() {
  return (
    <Container>
      <ContentWrapper>
        <TextWrapper>
          <VpagLogo />
          <Text
            size="4xl"
            weight="500"
            variant="tertiary"
            className="section-title"
            lineHeight="45px"
          >
            {i18n.t('vpagSection.title')}
          </Text>
          <Text variant="secondary" size="md">
            {i18n.t('vpagSection.description')}
          </Text>
        </TextWrapper>
        <SectionImage src={image} alt="Vpag Wallet and Vpag Stores" />
      </ContentWrapper>
      <SwiperWrapper
        breakpoints={SWIPER_BREAKPOINTS}
        modules={[Pagination, Navigation]}
        spaceBetween={SPACE_BETWEEN_CARDS}
        pagination={{
          clickable: true,
          renderCustom: (index, className) =>
            `<span className="dot swiper-pagination-bullet">${index}</span>`,
        }}
        navigation={{
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev',
        }}
      >
        {data.map(({ title, icon }) => (
          <SwiperSlide key={title}>
            <CarouselCard title={title} icon={icon} />
          </SwiperSlide>
        ))}
        <ArrowRight className="swiper-btn-next" />
        <ArrowLeft className="swiper-btn-prev" />
      </SwiperWrapper>
      <ExternalLinkButton
        target="_blank"
        url="https://vpag.com"
        label={i18n.t('vpagSection.getToKnow')}
        className="absolute-btn"
      />
    </Container>
  );
}

export default VpagSection;
