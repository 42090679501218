import React from 'react';

import Header from '@/components/organisms/Header';
import i18n from '@/translate/i18n';
import { ContactButton } from '@/components/atoms/Button/ContactButton/styles';
import { ReactComponent as LatamFlags } from '@/assets/flags.svg';

import {
  HashLink,
  HeroTitle,
  Text,
} from '@/components/atoms/Typography/styles';

import { Container, ContentWrapper } from './style';

function HeroSection() {
  return (
    <Container>
      <Header bg="transparent" />
      <ContentWrapper>
        <Text size="lg" color="light">
          {i18n.t('hero.affiliateMarketing')}
        </Text>
        <HeroTitle>{i18n.t('hero.title')}</HeroTitle>
        <LatamFlags />
        <HashLink smooth to="#contact">
          <ContactButton>{i18n.t('hero.contactUs')}</ContactButton>
        </HashLink>
      </ContentWrapper>
    </Container>
  );
}

export default HeroSection;
