import styled, { css } from 'styled-components';
import { SectionContainer } from '@/components/atoms/SectionContainer/styles';
import { Button } from '@/components/atoms/Button/styles';

export const Container = styled(SectionContainer)`
  padding-bottom: 3rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 8rem 0 3rem;

    h1 {
      max-width: 28rem;
    }

    p {
      max-width: 28rem;
    }
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  max-width: 1070px;
  padding: 0 2rem;
  width: 100%;

  #hashLink {
    display: none;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;

    div {
      flex: 1;
      min-width: 50%;
      text-align: left;
    }
  }
`;

export const Image = styled.img`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 50%;
    width: 100%;
  }
`;

export const ContactButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    border-radius: 4rem;
    font-weight: bold;
    margin: 1rem 0;

    &:hover {
      border: 1px solid ${theme.colors.primary};
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      margin: 5rem 0;
      width: 280px;
    }
  `}
`;
