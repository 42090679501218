import styled from 'styled-components';
import { ReactComponent as Chevron } from '@/assets/icons/chevron-down.svg';

export const Container = styled.div`
  bottom: 0;
  cursor: pointer;
  left: 50%;
  margin-bottom: 1rem;
  position: absolute;
  transform: translate(-50%, 0%);

  svg {
    stroke: ${({ theme, color = 'primary' }) => theme.colors[color]};
  }
`;

export const ChevronDown = styled(Chevron)`
  height: 3rem;
  margin: auto;
  width: 3rem;
`;
