import styled from 'styled-components';

import { Container, MerchantWrapper } from '../OurClients/styles';

export const Wrapper = styled(Container)`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100vh;
  padding-bottom: 7rem;
  position: relative;

  img {
    width: 55px;
  }

  .merchants {
    width: 95%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 3vh;
    height: 900px;
    padding-bottom: 10rem;

    .merchants {
      width: 75%;
    }

    img {
      width: 120px;
    }

    h1 {
      padding: 0 2rem 0rem 11rem;
      text-align: left;
    }
  }
`;

export const MerchantsBox = styled(MerchantWrapper)`
  padding: 1rem 0;
  width: 75%;
`;
