import { createGlobalStyle } from 'styled-components';

import ArboriaBook from '@/fonts/Arboria-Book.ttf';
import ArboriaMedium from '@/fonts/Arboria-Medium.ttf';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Arboria Book';
    src: url(${ArboriaBook}) format('truetype');
  }

  @font-face {
    font-family: 'Arboria Medium';
    src: url(${ArboriaMedium}) format('truetype');
  }

  * {
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  #root {
    white-space: pre-line;
  }

  html, body, #root {
    background-color: #000;
    color: #fff;
    font-family: 'Arboria Medium', sans-serif;
    height: 100%;
    min-height: 100%;
  }

  html {
    font-size: 14px;

    @media screen and (min-width: 1070px) {
      font-size: 16px;
    }
  }

  [disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  button {
    cursor: pointer;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

`;

export default GlobalStyle;
