import styled from 'styled-components';

export const Wrapper = styled.div`
  display: ${({ hideInMobile }) => (hideInMobile ? 'none' : 'block')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: ${({ hideInDesktop }) => (hideInDesktop ? 'none' : 'block')};
  }
`;

export const Image = styled.img`
  border-radius: 20px;
  height: 156px;
  max-width: 95vw;
  width: 280px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 220px;
    width: 370px;
  }
`;
