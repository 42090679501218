import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import {
  ExternalLink,
  HashLink,
  NavLink,
} from '@/components/atoms/Typography/styles';

import { Container, Item } from './styles';

function MobileMenu({ setShowMenu, showMenu }) {
  return (
    <Container showMenu={showMenu}>
      <Item onClick={() => setShowMenu(false)}>
        <NavLink to="/affiliate">{i18n.t('header.affiliate')}</NavLink>
      </Item>
      <Item onClick={() => setShowMenu(false)}>
        <NavLink to="/about-us">{i18n.t('header.about')}</NavLink>
      </Item>
      <Item onClick={() => setShowMenu(false)}>
        <HashLink smooth to="/#brands">
          {i18n.t('header.brands')}
        </HashLink>
      </Item>
      <Item onClick={() => setShowMenu(false)}>
        <HashLink smooth to="/#clients">
          {i18n.t('header.clients')}
        </HashLink>
      </Item>
      <Item onClick={() => setShowMenu(false)}>
        <ExternalLink href="https://vpag.com" target="_blank">
          VPag
        </ExternalLink>
      </Item>
      <Item onClick={() => setShowMenu(false)}>
        <HashLink smooth to="/#contact">
          {i18n.t('header.contact')}
        </HashLink>
      </Item>
    </Container>
  );
}

MobileMenu.propTypes = {
  setShowMenu: PropTypes.func,
  showMenu: PropTypes.bool,
};

export default MobileMenu;
