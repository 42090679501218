import styled from 'styled-components';

import { DefaultContainer } from '@/components/atoms/Containers/styles';

export const Container = styled(DefaultContainer)`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  min-height: 55vh;
  padding: 4rem 0;
  position: relative;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  max-width: 1070px;
  padding: 0 1rem;
  text-align: center;
  width: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 90%;
  padding: 0 1rem;

  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    gap: 2rem;
    justify-content: flex-end;
    text-align: left;
    width: 80%;

    & > p {
      margin-top: 2.5rem;
    }

    div {
      align-items: flex-start;
      max-width: 50%;
    }
  }
`;

export const MerchantWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.light};
  border-radius: 30px;
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  justify-content: center;
  padding: 3rem 0;
  width: 90%;

  img {
    width: 120px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 3rem;
  }
`;
