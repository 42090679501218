export const colors = {
  black: '#000000',
  lightGreen: '#00ac33',
  pix: '#32bcad',
  red: '#db0000',
  white: '#ffffff',

  blue: {
    100: '#7db9ed',
    500: '#247fd0',
  },

  darkGray: {
    100: '#6e757a',
    200: '#5f666a',
    300: '#5f666a',
    400: '#333434',
    500: '#434546',
    600: '#373d3f',
    700: '#2b2d2e',
    800: '#262623',
    850: '#464646',
    900: '#141414',
  },

  gray: {
    10: '#141414b4',
    50: '#f8f8f8',
    100: '#f1f1f1',
    200: '#d8d8d8',
    400: '#a9a9a9',
    500: '#888888',
    600: '#4b5258',
    700: '#393939',
    800: '#32373a',
  },

  green: {
    100: '#96e0aa',
    500: '#3fc763',
    600: '#2fb051',
  },

  yellowGreenDark: {
    100: '#a6ce28',
    200: '#9cc224',
    300: '#93b720',
    400: '#89ab1b',
    500: '#7f9f17',
  },

  yellowGreenLight: {
    100: '#e6fc8b',
    200: '#dcfb76',
    300: '#d3fa61',
    400: '#c9f84b',
    500: '#bff736',
  },
};

export const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha || 1})`;
};
