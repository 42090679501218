import React, { useState } from 'react';

import { HashLink } from 'react-router-hash-link';
import i18n from '@/translate/i18n';
import { Text } from '@/components/atoms/Typography/styles';

import data from './data';
import Modal from '../Modal';

import {
  Container,
  ContentWrapper,
  ExternalButton,
  Item,
  ItemsWrapper,
} from './styles';

function EventSchedule() {
  const [modalBanner, setModalBanner] = useState(null);
  return (
    <Container>
      <ContentWrapper>
        <Text size="4xl" weight="700">
          {i18n.t('eventsSchedule.title')}
        </Text>
        <Text>{i18n.t('eventsSchedule.subtitle')}</Text>
        <ItemsWrapper>
          {data.map(
            ({
              title,
              schedule,
              location,
              image,
              url,
              isDisabled,
              bannerImage,
            }) => (
              <Item key={title}>
                <img src={image} alt={title} />
                <Text size="lg" weight="500">
                  {title}
                </Text>
                <Text weight="500" color="primary">
                  {schedule}
                </Text>
                <Text>{location}</Text>
                {!isDisabled && (
                  <HashLink smooth to={url ?? ''}>
                    <ExternalButton
                      onClick={() => bannerImage && setModalBanner(bannerImage)}
                    >
                      {i18n.t('eventsSchedule.requestAppointment')}
                    </ExternalButton>
                  </HashLink>
                )}
              </Item>
            ),
          )}
        </ItemsWrapper>
      </ContentWrapper>
      <Modal
        isOpen={Boolean(modalBanner)}
        handleModal={setModalBanner}
        bannerImage={modalBanner}
      />
    </Container>
  );
}

export default EventSchedule;
