import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: baseline;
  background-color: ${({ theme }) => theme.colors.bg.carouselCard};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 180px;
  margin: auto;
  padding: 2rem 1rem;
  text-align: left;
  width: 350px;

  p {
    max-width: 300px;
  }

  img {
    max-width: 40px;
    max-height: 30px;
  }
`;
