import React from 'react';

import Footer from '@/components/organisms/Footer';
import AffiliateHeroSection from '@/components/organisms/AffiliateHero';
import TrustSection from '@/components/organisms/TrustSection';
import HowWeWork from '@/components/organisms/HowWeWork';
import AffiliateInfluencersSection from '@/components/organisms/AffiliateInfluencersSection';
import InfluencersWorkSteps from '@/components/organisms/InfluencersWorkSteps';
import GetMoreSection from '@/components/organisms/GetMoreSection';
import AffiliateForm from '@/components/organisms/AffiliateForm';
import i18n from '@/translate/i18n';

function Affiliate() {
  i18n.changeLanguage('pt');
  return (
    <>
      <AffiliateHeroSection />
      <TrustSection />
      <HowWeWork />
      <AffiliateInfluencersSection />
      <InfluencersWorkSteps />
      <GetMoreSection />
      <AffiliateForm />
      <Footer />
    </>
  );
}

export default Affiliate;
