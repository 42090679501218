import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

const LinkStyles = css`
  ${({ hideInMobile, theme, variant }) => `
    color: ${theme.colors.light};
    display: ${hideInMobile ? 'none' : 'block'};
    font: ${theme.font.size.md} ${theme.font.family[variant ?? 'primary']};
    text-decoration: none;
    transition: 0.3s color ease;

    &:hover {
      color: ${theme.colors.primary};
    }
  `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: ${({ hideInDesktop }) => (hideInDesktop ? 'none' : 'inline')};
  }
`;

export const NavLink = styled(Link)`
  ${LinkStyles}
`;

export const ExternalLink = styled.a`
  ${LinkStyles}
`;

export const HashLink = styled(NavHashLink)`
  ${LinkStyles}
`;

export const HeroTitle = styled.h1`
  color: ${({ theme, color = 'light' }) => theme.colors.info[color]};
  font: ${({ theme }) =>
    `700 ${theme.font.size['4xl']} ${theme.font.family.primary}`};
  line-height: 50px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.font.size['6xl']};
    line-height: 84px !important;
  }
`;

export const Text = styled.p`
  ${({ color, size, theme, variant, weight, lineHeight = 'normal' }) => css`
    line-height: ${lineHeight} !important;
    color: ${theme.colors[color ?? 'dark']};
    font: ${weight ?? '400'} ${theme.font.size[size ?? 'base']}
      ${theme.font.family[variant ?? 'primary']};
  `}
`;
