import styled from 'styled-components';

export const Overlay = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.overlay};
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transform: scale(${({ isOpen }) => (isOpen ? 1 : 0)});
  transition: opacity 0.4s ease-out, transform 0.1s ease;
  width: 100%;
  z-index: ${({ isOpen }) => (isOpen ? 99 : -99)};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

export const ModalBody = styled.div`
  background-color: white;
  border-radius: 2rem;
  height: 550px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 95%;
    height: 450px;
  }
`;

export const EventImg = styled.img`
  height: 450px;
  margin-bottom: 1.5rem;
  width: 450px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    height: 356px;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: transparent;
  color: ${({ theme }) => theme.colors.light};
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
`;
