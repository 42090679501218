import React from 'react';

import { Text } from '@/components/atoms/Typography/styles';

import {
  Container,
  ContentWrapper,
  InfoWrapper,
  TextWrapper,
  Section,
} from './styles';
import i18n from '@/translate/i18n';
import { colors } from '@/styles/colors';

function HowWeDoIt() {
  const sectionBg = {
    0: colors.gray[100],
    1: colors.green[600],
    2: colors.green[500],
  };
  const sectionColor = {
    0: 'primary',
    1: 'white',
    2: 'dark',
  };
  return (
    <Container>
      <ContentWrapper>
        <TextWrapper>
          <Text size="5xl" weight="700" color="primary">
            {i18n.t('howWeDoIt.title')}
          </Text>
          <div>
            <Text size="2xl" weight="700" color="light">
              {i18n.t('howWeDoIt.description')}
            </Text>
            <Text size="lg" color="light">
              {i18n.t('howWeDoIt.content')}
            </Text>
          </div>
        </TextWrapper>
      </ContentWrapper>
      <InfoWrapper>
        {i18n
          .t('howWeDoIt.data', {
            returnObjects: true,
          })
          .map(({ title }, index) => (
            <Section key={title} className="section" color={sectionBg[index]}>
              <Text size="2xl" weight="700" color={sectionColor[index]}>
                {title}
              </Text>
            </Section>
          ))}
      </InfoWrapper>
    </Container>
  );
}

export default HowWeDoIt;
