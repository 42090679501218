import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import { ChevronDown, Container } from './styles';

function ArrowDown({ color, url }) {
  return (
    <HashLink smooth to={url} id="hashLink">
      <Container color={color}>
        <ChevronDown />
      </Container>
    </HashLink>
  );
}

ArrowDown.propTypes = {
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default ArrowDown;
