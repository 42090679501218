import styled from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.dark};
  display: flex;
  flex-direction: column;
  height: ${({ showMenu }) => (showMenu ? '150px' : '0')};
  min-height: ${({ showMenu }) => (showMenu ? '150px' : '0')};
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 58px;
  transition: 0.3s height ease, 0.3s min-height ease;
  width: 100%;
  z-index: 3;
`;

export const Item = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) =>
    `1px solid ${hexToRGBA(theme.colors.input.lines, 0.2)}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.3rem 0;
  width: 90%;

  &:first-of-type {
    border-top: ${({ theme }) =>
      `1px solid ${hexToRGBA(theme.colors.input.lines, 0.2)}`};
  }

  &:hover {
    background-color: ${({ theme }) => hexToRGBA(theme.colors.bg.primary, 0.1)};
  }
`;
