import styled from 'styled-components';

import { ReactComponent as VPagLogo } from '@/assets/vpag-logo-white.svg';

export const Container = styled.nav`
  align-items: center;
  display: flex;
  gap: 4rem;

  button {
    font-size: ${({ theme }) => theme.font.size.md};
    height: 39px;
    width: 124px;
  }
`;

export const LogoVpag = styled(VPagLogo)`
  width: 80px;
`;
