import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@/components/atoms/Typography/styles';

import { Wrapper } from './styles';

function CarouselCard({ title, icon }) {
  return (
    <Wrapper>
      <img src={icon} alt={title} />
      <Text size="xl" variant="tertiary">
        {title}
      </Text>
    </Wrapper>
  );
}

CarouselCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
};

export default CarouselCard;
