import styled from 'styled-components';
import { SectionContainer } from '@/components/atoms/SectionContainer/styles';

export const Container = styled(SectionContainer)`
  align-items: center;

  p {
    font-size: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    h1 {
      max-width: 20rem;
      line-height: 64px;
    }

    p {
      font-size: 1.35rem;
      max-width: 32rem;
    }
  }
`;

export const Influencers = styled.img`
  margin: 10rem 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 1rem 0;
  }
`;
